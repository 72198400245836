<template>
  <div
    class="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-10"
    v-show="visible"
  >
    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="max-w-sm w-full bg-white shadow-xl rounded-lg pointer-events-auto"
        v-show="visible"
      >
        <div class="rounded-lg shadow-xl overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <i
                  :class="icon"

                  class="h-4 w-4 fill-current"
                ></i>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm leading-5 font-medium text-gray-900">{{header}}</p>
                <p class="mt-1 text-sm leading-5 text-gray-500">{{body}}</p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  @click="close"
                >
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ConstPlugin from "./ConstPlugin";
export default {
  props: {
    message: {
      type: String,
      default: null
    }
  },
  data() {
    return {
        header: this.title,
        body: this.message,
        visible: false,
        backgroundColor: null,
        textColor: null,
        icon: null
    };
  },
  created() {
    if (this.message) {
      this.flash(this.message);
    }

    window.events.$on("flash", message => this.flash(message));
  },
  methods: {
    flash(message) {
      this.initializeContent(message);
      this.visible = true;
      this.hide();
    },
    initializeContent(message) {
      const data = message.split("|");
      this.header = ConstPlugin.Types[data[0]]; //0 pertains to type
      this.body = data[1]; //1 pertains to message
      this.backgroundColor = ConstPlugin.BackgroundColors[data[0]];
      this.textColor = ConstPlugin.TextColors[data[0]];
      this.icon = `${ConstPlugin.ModalIcons[data[0]]} ${this.textColor}`;

    },
    hide() {
      setTimeout(() => {
        this.visible = false;
      }, 5000);
    },
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    }
  }
};
</script>

<style>
.alert-flash {
  position: fixed;
  right: 25px;
  bottom: 25px;
}
</style>
