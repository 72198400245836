<template>
    <div class="mt-1 w-full">
        <v-date-picker
            is-required
            v-model="date"
            :masks="masks"
            :max-date="until"
            color="teal">
            <template v-slot="{ inputValue, inputEvents }">
                <input
                    :name="name"
                    class="px-3 py-2 rounded-md shadow-sm border border-gray-300 rounded focus:outline-none w-full"
                    :value="inputValue"
                    v-on="inputEvents"
                />
            </template>
        </v-date-picker>
    </div>
</template>

<script>


export default {
    props: {
        name: {type: String, default: 'date'},
        oldValue: { type: String, default: null },
        until: { type: Date, default: () => new Date()}
    },

    data() {
        return {
            date: new Date(),
            masks: {
                input: 'YYYY-MM-DD',
            },
        };
    },
    mounted() {
        if(this.oldValue !== null) {
            this.date = new Date(this.oldValue);
        }

    },
};
</script>
