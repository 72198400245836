import axios from 'axios';

class UbiqForm {
	constructor(data) {
		this.originalData = JSON.parse(JSON.stringify(data));
		Object.assign(this, data);

		this.errors = {};
		this.submitted = false;
	}

	data() {

		return Object.keys(this.originalData).reduce((data, attribute) => {
			data[attribute] = this[attribute];
			return data;
		}, {});
	}
	post(endpoint) {
		return this.submit(endpoint, 'post');
	}

	patch(endpoint) {
		return this.submit(endpoint, 'patch');
	}

	delete(endpoint) {
		return this.submit(endpoint, 'delete');
	}
	async submit(endpoint, requestType = 'post') {

		return await axios[requestType](endpoint, this.data())
			.catch(this.onFail.bind(this))
			.then(this.onSuccess.bind(this));

	}
	onSuccess(response) {
		this.submitted = true;
		this.errors = {};
		return response;
	}
	onFail(error) {
		this.submitted = false;
		this.errors = error.response.data.errors;
		throw error;
	}

	reset() {
		Object.assign(this, this.originalData);
	}
}

export default UbiqForm;