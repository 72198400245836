<template>
    <tr class="even:bg-gray-50 hover:bg-gray-100">
        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {{category}}
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
            <label for="enabler"
                   :class="{'bg-gray-200' : !shouldAllBeEnabled, 'bg-teal-500' : shouldAllBeEnabled }" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                   @click="toggleAll"
            >
                <span class="sr-only">Use setting</span>
                <span aria-hidden="true" :class="{'translate-x-0' : !shouldAllBeEnabled, 'translate-x-5' : shouldAllBeEnabled }" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
                    <span :class="{'opacity-100 ease-in duration-200' : !shouldAllBeEnabled, 'opacity-0 ease-out duration-100' : shouldAllBeEnabled }" class="opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
                        <svg class="bg-white h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <span :class="{'opacity-0 ease-out duration-100' : !shouldAllBeEnabled, 'opacity-100 ease-in duration-200' : shouldAllBeEnabled }" class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity" aria-hidden="true">
                        <svg class="bg-white h-3 w-3 text-teal-600" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
                <input type="checkbox"  v-model="shouldAllBeEnabled" class="absolute inset-0 sr-only" >
            </label>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center" v-for="permission in permissions" :id="permission.id">
            <ubiq-slider :permission="permission" :enabled="crud[permission.slug]" @toggle="toggleAbility"></ubiq-slider>
        </td>

    </tr>
</template>

<script>

import UbiqSlider from "./UbiqSlider";

export default {
    components: { UbiqSlider},
    props: {
        category: {type: String, default: null},
        permissions: { type: Array, default: () => [] },
        abilities: { type: Object, default: () => {} },
    },
    data() {
        return {
            checkAllIsEnabled: false,
            crud: {}
        }
    },
    watch: {
        shouldAllBeEnabled(val) {
            this.checkAllIsEnabled = val;
        }
    },
    computed: {
        shouldAllBeEnabled() {
            return Object.keys(this.crud).every(key => this.crud[key] === true);
        }
    },
    methods: {
        toggleAll() {
            this.checkAllIsEnabled = !this.checkAllIsEnabled;
            this.permissions.map((permission) => this.toggleAbility(permission, this.checkAllIsEnabled));
            this.checkAllIsEnabled = this.shouldAllBeEnabled;

        },
        toggleAbility(permission, state) {
            this.$set(this.crud, permission.slug, state);
        }
    },
    mounted() {
        this.crud = this.abilities;
        this.checkAllIsEnabled = this.shouldAllBeEnabled;
    }

}
</script>


