<template>
  <div>

      <button
        type="button"
        class="btn"
        :class="buttonType"
        @click="isOpen = !isOpen"
      >
        <i
          class="`h-4 w-4 -ml-1 inline-flex items-center`"
              :class="buttonIcon"

        ></i>
        {{ filterText }}
      </button>

    <transition
      enter-active-class="ease-in-out duration-500"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in-out duration-500"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10"
        v-show="isOpen"
      ></div>
    </transition>
    <transition
      enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div class="fixed inset-0 overflow-hidden z-10" v-show="isOpen">
        <div class="absolute inset-0 overflow-hidden">
          <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
            <div class="w-screen max-w-md">
              <div
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
              >
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <header class="px-4 sm:px-6 mt-14 lg:mt-6">
                    <div class="flex items-start justify-between">
                      <h2 class="text-lg font-medium text-gray-900">
                        {{ header }}
                      </h2>

                      <div class="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          aria-label="Close panel"
                          class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500"
                          @click="close"
                        >
                          <svg
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div>
                      <p class="text-sm text-gray-500">
                        {{ message }}
                      </p>
                    </div>
                  </header>
                    <slot name="additional-content" />
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button type="button" class="btn secondary-btn" @click="close">
                      {{ cancelText }}
                    </button>
                    <button type="submit" v-if="isFilter" class="ml-4 btn primary-btn">
                      {{ submitText }}
                    </button>
                    <button type="submit" v-else name="submit_button" value="right_sidebar_submit" class="ml-4 btn primary-btn">
                        {{ submitText }}
                    </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ConstPlugin from "./ConstPlugin";

export default {
  props: {
    isFilter: {
        type: Boolean,
        default: false
    },
    filtersCount: {
      type: Number,
      default: 0,
    },
    anchor: {
      type: String,
      default: "Delete",
    },
    faIcon: {
      type: String,
      default: "fad fa-trash",
    },
    type: {
      type: String,
      default: "success",
    },
    header: {
      type: String,
      default: "Your header goes here.",
    },
    message: {
      type: String,
      default: "Your message goes here.",
    },
    submitText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  data() {
    return {
      isOpen: false,
      modalIcon: null,
      buttonIcon: null,
      backgroundColor: null,
      textColor: null,
      // duo: ConstPlugin.Duos["success"],
      buttonType: null,
    };
  },
  computed: {
    filterText() {
      if (this.filtersCount > 0) {
        return `${this.anchor} (${this.filtersCount})`;
      }
      return this.anchor;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
  mounted() {
    this.backgroundColor = ConstPlugin.BackgroundColors[this.type];
    this.textColor = ConstPlugin.TextColors[this.type];
    this.modalIcon = ConstPlugin.ModalIcons[this.type];
    this.buttonIcon = this.faIcon;
    // this.duo = ConstPlugin.Duos[this.type];
    this.buttonType = ConstPlugin.ButtonTypes[this.type];
  },
};
</script>
