<template>
    <div class="h-screen flex overflow-hidden bg-white">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <div class="lg:hidden">
            <transition
                enter-active-class="transition-opacity ease-linear duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-300"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div class="fixed inset-0 flex z-40" v-show="isOpen">
                    <div class="fixed inset-0">
                        <div class="absolute inset-0 bg-gray-600 opacity-75" @click="isOpen = !isOpen"></div>
                    </div>
                    <transition
                        enter-active-class="transition ease-in-out duration-300 transform"
                        enter-class="-translate-x-full"
                        enter-to-class="translate-x-0"
                        leave-active-class="transition ease-in-out duration-300 transform"
                        leave-class="translate-x-0"
                        leave-to-class="-translate-x-full"
                    >
                        <div
                            class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white"
                            v-show="isOpen"
                        >
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        @click="isOpen = !isOpen">
                                    <span class="sr-only">Close sidebar</span>
                                    <!-- Heroicon name: x -->
                                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div class="flex-shrink-0 flex items-center px-4">
                                <a href="/">
                                    <img class="logo w-auto" src="/img/logo.svg" alt="Ubiq" />
                                </a>
                            </div>
                            <div class="mt-5 flex-1 h-0 overflow-y-auto">
                                <slot name="mobile-nav" />
                            </div>
                        </div>
                    </transition>
                    <div class="flex-shrink-0 w-14">
                        <!-- Force sidebar to shrink to fix close icon -->
                    </div>
                </div>
            </transition>
        </div>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:flex lg:flex-shrink-0">
            <div class="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
                <div class="flex items-center flex-shrink-0 px-6">
                    <a href="/">
                        <img class="logo w-auto" src="/img/logo.svg" alt="Ubiq" />
                    </a>
                </div>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="h-0 flex-1 flex flex-col overflow-y-auto">
                    <slot name="ubiq-sidebar-profile" />
                    <!-- User account dropdown -->

                    <!-- Sidebar Search -->
<!--                    <div class="px-3 mt-5">-->
<!--                        <label for="search" class="sr-only">Search</label>-->
<!--                        <div class="mt-1 relative rounded-md shadow-sm">-->
<!--                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" aria-hidden="true">-->
<!--                                &lt;!&ndash; Heroicon name: search &ndash;&gt;-->
<!--                                <svg class="mr-3 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />-->
<!--                                </svg>-->
<!--                            </div>-->
<!--                            <input type="search" name="search" id="search" class="focus:ring-teal-300 focus:border-teal-400 block w-full pl-9 sm:text-sm border-gray-300 rounded-md" placeholder="Search">-->
<!--                        </div>-->
<!--                    </div>-->
                    <!-- Navigation -->
                    <slot name="mobile-nav" />
                </div>
            </div>
        </div>
        <!-- Main column -->
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <div class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
                <button class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden" @click="isOpen = !isOpen">
                    <span class="sr-only">Open sidebar</span>
                    <!-- Heroicon name: menu-alt-1 -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                </button>
                <div class="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
                    <div class="flex-1 flex">
<!--                        <form class="w-full flex md:ml-0" action="#" method="GET">-->
<!--                            <label for="search_field" class="sr-only">Search</label>-->
<!--                            <div class="relative w-full text-gray-400 focus-within:text-gray-600">-->
<!--                                <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">-->
<!--                                    &lt;!&ndash; Heroicon name: search &ndash;&gt;-->
<!--                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                                <input id="search_field" name="search_field" class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm" placeholder="Search">-->
<!--                            </div>-->
<!--                        </form>-->
                    </div>
                    <div class="flex items-center">
                        <!-- Profile dropdown -->
                        <div class="ml-3 relative">
                            <slot name="ubiq-mobile-profile" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- main tag -->
            <slot name="ubiq-main" />
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
        close() {
            this.isOpen = false;
        },


  }
};
</script>

<style>
</style>
