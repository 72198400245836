export default {
	methods: {
		shouldAppendValidClass(field) {
			return !field.$invalid && field.$model && field.$dirty;
		},    
		shouldAppendErrorClass(field) {
			return field.$error;
		}  
	}
};
