<template>
        <div class="mt-1 flex rounded-md">
            <vue-numeric
                :name="name"
                v-model.number="value"
                :class="addClass"
                class="block my-2 w-full rounded-md shadow-sm border-gray-300 focus:border-teal-400 focus:ring focus:ring-teal-300 focus:ring-opacity-50 placeholder-gray-300"
                v-bind:precision="precision"
                :currency-symbol-position="currencySymbolPosition"
                output-type="Number"
                :currency="currency"
                :minus="minus"
                :placeholder="placeholder"
                :empty-value="0"
            ></vue-numeric>
        </div>
</template>

<script>

import VueNumeric from "vue-numeric";

export default {
  components: { VueNumeric },
  props: {
    addClass: { type: String, default: "" },
    precision: { type: Number, default: 2 },
    currency: { type: String, default: "₱" },
    currencySymbolPosition: { type: String, default: "prefix"},
    name: { type: String, default: "value" },
    minus: { type: Boolean, default: false },
    placeholder: { type: String, default: "Please enter amount." },
    oldValue: { type: String, default: "" },
  },
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
      this.value = this.oldValue;
  },
};

</script>
