<template>
  <div>
    <span class="rounded-md mt-2">
      &nbsp;<a :class="buttonType" class="cursor-pointer"  @click="toggle" v-if="isIconOnly">
        <i
            :class="modalIcon"
            class="mx-auto flex items-center justify-center h-5 w-5 fill-current"
        ></i>
      </a>
        <button type="button" :class="buttonType" @click="toggle" v-else>

            {{ anchor }}
      </button>
    </span>
    <transition
      enter-active-class="transition-opacity ease-linear duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity ease-linear duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-10"
        v-show="isOpen"
      >
        <div class="fixed inset-0 transition-opacity">
          <div
            class="absolute inset-0 bg-gray-500 opacity-75"
            @click="toggle"
          ></div>
        </div>

        <transition
          enter-active-class="ease-in-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            v-show="isOpen"
          >
            <div>
              <div
                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full"
                :class="backgroundColor"
              >
              <i
                  :class="modalIcon"
                  class="mx-auto flex items-center justify-center h-6 w-6 fill-current"
              ></i>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ header }}
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">{{ message }}</p>
                </div>
                <slot name="additional-content" />
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
                <button
                  type="button"
                  class="w-full btn primary-btn"
                  name="submit_button"
                  value="submit"
                  @click="store"
                  :disabled="isInvalid || isSaving"
                >
                  <span v-if="isSaving">Saving...</span>
                  <span v-else>{{ submitText }}</span>
                </button>
              </span>
              <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
              >
                <button
                  type="button"
                  class="w-full btn secondary-btn"
                  @click="cancel"
                >
                  {{ cancelText }}
                </button>
              </span>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import ConstPlugin from "./ConstPlugin";

export default {
  props: {
    anchor: {
      type: String,
      default: "Delete",
    },
    isIconOnly : {
        type: Boolean,
        default: false
    },
    faIcon: {
      type: String,
      default: "fad fa-trash",
    },
    type: {
      type: String,
      default: "primary",
    },
    header: {
      type: String,
      default: "Your header goes here.",
    },
    message: {
      type: String,
      default: "Your message goes here.",
    },
    submitText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalIcon: null,
      buttonIcon: null,
      backgroundColor: null,
      textColor: null,
      buttonType: null,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    store() {
      this.$emit("store");
    },
    toggle() {
      this.$emit("toggle");
    },
  },
  mounted() {


      this.backgroundColor = ConstPlugin.BackgroundColors[this.type];
      this.textColor = ConstPlugin.TextColors[this.type];

      // this.buttonIcon = this.faIcon;
      this.buttonType = ConstPlugin.ButtonTypes[this.type];
      this.modalIcon = `${this.faIcon} ${this.textColor}`;
  },
};
</script>
