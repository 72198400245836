<template>
    <div class="w-full">

        <span>
      <ubiq-form-modal
          :type="'new'"
          :header="'Add New Item'"
          :message="'Fill up the form below to add an item.'"
          :submit-text="'Submit'"
          :anchor="'Add New'"
          :is-icon-anchor="true"
          :fa-icon="'far fa-pencil'"
          :is-open="isOpen"
          :is-invalid="isInvalid"
          :is-saving="isSaving"
          @store="store"
          @toggle="toggle"
          @cancel="cancel"
      >
        <template v-slot:additional-content>
          <div class="text-center mt-3">
            <label for="name" class="block font-medium text-sm text-gray-700">
              Product <span class="text-red-500">*</span>
            </label>


            <label for="quantity" class="block font-medium text-sm text-gray-700">
              Quantity <span class="text-red-500">*</span>
            </label>

              <vue-numeric
                  v-model="form.quantity"
                  class="block my-2 w-full rounded-md shadow-sm border-gray-300 focus:border-teal-400 focus:ring focus:ring-teal-300 focus:ring-opacity-50 placeholder-gray-300 text-center"
                  v-bind:precision="0"
                  output-type="String"
                  :minus="false"
                  placeholder="Minimum of 1"
                  :empty-value="1"
                  @blur="$v.form.quantity.$touch()"
              ></vue-numeric>
            <div
                v-if="$v.form.quantity.$error && !$v.form.quantity.required"
            >
              <span class="error-message">Quantity is required.</span>
            </div>
              <div
                  v-else-if="$v.form.quantity.$error && !$v.form.quantity.numeric"
              >
              <span class="error-message">Quantity must be numeric.</span>
            </div>
              <div
                  v-else-if="$v.form.quantity.$error && !$v.form.quantity.minValue"
              >
              <span class="error-message">Quantity be at least 1.</span>
            </div>
              <label for="quantity" class="block font-medium text-sm text-gray-700">
                Unit Cost
              </label>
              <vue-numeric
                  v-model="form.price"
                  class="block my-2 w-full rounded-md shadow-sm border-gray-300 focus:border-teal-400 focus:ring focus:ring-teal-300 focus:ring-opacity-50 placeholder-gray-300 text-center"
                  v-bind:precision="2"
                  output-type="String"
                  :minus="false"
                  placeholder="Leave blank if unknown"
                  :empty-value="0"
              ></vue-numeric>
          </div>
        </template>
      </ubiq-form-modal>
    </span>
    </div>
</template>

<script>
import UbiqForm from "./UbiqForm";
import VueNumeric from "vue-numeric";
import { required, numeric, minValue } from "vuelidate/lib/validators";


export default {
    components: { VueNumeric},
    props: {
        uuid: {type: String, default: null},
        products: { type: [Array, Object], default: () => [] },
    },
    data() {
        return {
            form: new UbiqForm({
                price: "0",
                quantity: "0",
                product: null,
                submit_button: 'submit'
            }),
            isOpen: false,
            isSaving: false,
            unit: [{}],
        };
    },
    validations: {
        form: {
            quantity: {
                required,
                numeric,
                minValue: minValue(1)
            },
            product: {
                required,

            },
        },
    },
    computed: {
        isInvalid() {
            return this.$v.form.$invalid;
        },
    },
    methods: {

        reset() {
            this.$v.$reset();
            this.form.reset();
        },
        close() {
            this.isOpen = false;
        },
        cancel() {
            this.reset();
            this.close();
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        addNewItem({id, text}) {

            this.units.push({ id: id, text: text });
            this.spliceIfHasPreselectedUnit();
            this.unit.push({ id: id, text: text });

        },
        spliceIfHasPreselectedUnit()
        {
            if(this.unit.length > 0) {
                this.unit.splice(0);
            }
        },

        store() {
            this.$v.form.$touch();

            if (!this.$v.form.$invalid) {
                try {
                    this.isSaving = true;

                    this.form.post(`/purchase-orders/${this.uuid}/items`).then((response) => {
                        if (response.data.errors) {
                            window.flash(response.data.message);
                            this.reset();
                        } else {
                            //The response is successful.
                            window.flash(response.data.message);

                            // this.addNewItem(response.data.unit);
                            this.reset();
                            this.close();
                        }
                        this.isSaving = false;
                    });
                } catch (error) {
                    this.isSaving = false;
                    this.form.errors = error.response.data.errors;
                }
            }
        },
    },
    mounted() {},
};
</script>

<style>
</style>
