<template>
  <div class="dropdown relative">
    <div class="dropdown-toggle" @click.prevent="isOpen = !isOpen" >
      <slot name="trigger" />
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="isOpen"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
        role="menu"
        class="z-10 origin-top-right absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200"
        :class="align === 'left' ? 'left-0' : 'right-0'"
        :style="{width}"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
    },
    width: {
      type: String,
      default: "auto",
    },
      reference: {
        type: String,
        default: "dropdownMenu"
      }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    // isOpen(isOpen) {
    //   if (isOpen) {
    //     document.addEventListener("click", this.closeIfClickedOutside);
    //   }
    // },
  },

    created() {
      document.addEventListener("click", this.closeIfClickedOutside);
    },
    destroyed() {
      document.removeEventListener("click", this.closeIfClickedOutside);
    },
    methods: {
        closeIfClickedOutside(event) {
      // if (!event.target.closest(".dropdown")) {
      //   this.isOpen = false;
      //   document.removeEventListener("click", this.closeIfClickedOutside);
      // }
        if (!this.$el.contains(event.target)) {
            this.isOpen = false;
        }
    },
  },
};
</script>

<style>
</style>
