require('./bootstrap');

// require('alpinejs');
import Alpine from 'alpinejs';

window.Vue = require('vue');
window.Alpine = Alpine;
Alpine.start();

window.events = new window.Vue.default();
window.flash = function (message) {
    window.events.$emit('flash', message);
};

import Vuelidate from 'vuelidate';
window.Vue.default.use(Vuelidate);
//
import VCalendar from 'v-calendar';
window.Vue.default.use(VCalendar);

//App Components
window.Vue.default.component('ubiq-app', require('./components/UbiqApp.vue').default);
window.Vue.default.component('ubiq-flash', require('./components/UbiqFlash.vue').default);
window.Vue.default.component('ubiq-modal', require('./components/UbiqModal.vue').default);
window.Vue.default.component('ubiq-form-modal', require('./components/UbiqFormModal.vue').default);
window.Vue.default.component('ubiq-dropdown', require('./components/UbiqDropdown.vue').default);
window.Vue.default.component('ubiq-currency', require('./components/UbiqCurrency').default);
window.Vue.default.component('ubiq-right-sidebar', require('./components/UbiqRightSidebar.vue').default);
window.Vue.default.component('ubiq-permission-item', require('./components/UbiqPermissionItem.vue').default);
window.Vue.default.component('ubiq-change-password-modal', require('./components/UbiqChangePasswordModal.vue').default);
window.Vue.default.component('ubiq-list', require('./components/UbiqList.vue').default);
window.Vue.default.component('ubiq-price-checker', require('./components/UbiqPriceChecker.vue').default);
window.Vue.default.component('ubiq-date', require('./components/UbiqDate.vue').default);
window.Vue.default.component('ubiq-product-unit-list', require('./components/UbiqProductUnitList.vue').default);
window.Vue.default.component('ubiq-group-list', require('./components/UbiqGroupList.vue').default);
window.Vue.default.component('ubiq-purchase-order-item', require('./components/UbiqPurchaseOrderItem.vue').default);


//Charts
window.Vue.default.component('bar-chart', require('./components/charts/BarChart.vue').default);
window.Vue.default.component('pie-chart', require('./components/charts/PieChart.vue').default);
window.Vue.default.component('horizontal-chart', require('./components/charts/HorizontalChart.vue').default);
window.Vue.default.component('line-chart', require('./components/charts/LineChart.vue').default);

if (process.env.MIX_APP_ENV === 'production') {
    window.Vue.default.config.devtools = false;
    window.Vue.default.config.debug = false;
    window.Vue.default.config.silent = true;
}

if(document.getElementById('app')) {
    new window.Vue.default({
        el: '#app',

        methods: {
            toggle(event) {
                let isChecked = event.target.checked;

                this.$el.querySelectorAll('.checkboxer').forEach(function(el) {
                    el.checked = isChecked;
                });

                this.checkIfToggleAllIsChecked();
            },

            checkIfToggleAllIsChecked()
            {
                this.$refs.all.checked = this.shouldTogglerBeEnabled();
            },

            shouldTogglerBeEnabled()
            {
                return (this.$el.querySelectorAll('.checkboxer').length) === (this.$el.querySelectorAll('.checkboxer:checked').length);
            },

            disable(event)
            {
                event.submitter.style.pointerEvents = 'none';
                event.submitter.style.opacity = '0.5'
            },

            quote()
            {
                let type = undefined;
                let product = undefined;

                let override = document.getElementById('override');

                if (override.selectedIndex > 0) {
                    type = override.options[override.selectedIndex].value;
                }

                let selection = document.getElementById('product-selection');

                if (selection) {
                    product = selection.value;
                }

                let price = document.getElementById('checker')

                if (type !== undefined && product !== undefined && type !== 'no') {

                    let url = (type === 'yes') ? `/quote/${product}` : `/quote/${product}/type/${type}/customer/${(document.getElementById('customer').value)}`

                    axios.get(url)
                        .then((response) => {
                            price.value = response.data.price;
                    })
                } else {
                    price.value = '';
                }
            }
        }
    });
}

