<script>
import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
    props: {
        datasource: { type: [Object], default: () => {} },
    },
    data: () => ({
        chartdata: {},
    options: {
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return `${data.labels[tooltipItem.index]} : ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}%`;
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false
    }
  }),

    methods: {
        initialize({labels, datasets})
        {
            this.chartdata.labels = labels;
            this.chartdata.datasets = datasets;
        }
    },
    mounted () {
        this.initialize(this.datasource);
        this.renderChart(this.chartdata, this.options)
    }
}
</script>
