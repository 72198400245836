<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
    props: {
        datasource: { type: [Object], default: () => {} },
    },
  data: () => ({
    chartdata: {},
    options: {
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return `${data.labels[tooltipItem.index]} : ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}`;
                },
                title: function(tooltipItem, data) {
                    return data.datasets[tooltipItem[0].datasetIndex].label;
                }
            }
        },
        scales: {
            yAxes: [{

                ticks: {
                    beginAtZero: true,
                    callback : function(value,index,array) {
                        return (value < 1000000) ? value/1000 + 'K' : value/1000000 + 'M';
                    }
                },
                gridLines: {
                    display: true
                }
            }],
            xAxes: [{

                ticks: {
                    beginAtZero: true,
                },
                gridLines: {
                    display: false
                }
            }]
        },
        responsive: true,
        maintainAspectRatio: false
    }
  }),
  methods: {
    initialize({labels, datasets})
        {
            this.chartdata.labels = labels;
            this.chartdata.datasets = datasets;
        }
  },
  mounted () {
    this.initialize(this.datasource);
    this.renderChart(this.chartdata, this.options)
  }
}
</script>
