<template>
  <div>
    <select :multiple="multiple" :name="name" :id="name" class="hidden">
      <option
          id="product-selection"
          v-for="option in getValue"
          :key="option.id"
          :value="option.id"
          selected
      >{{ option.text }}</option>
    </select>
    <multiselect
        :options="allOptions"
        :selected="selected"
        :value="value"
        :multiple="multiple"
        :searchable="true"
        :allow-empty="true"
        :close-on-select="closeOnSelect"
        :clear-on-select="clearOnSelect"
        :hide-selected="hideSelected"
        :placeholder="placeholder"
        :taggable="taggable"
        v-model="value"
        select-label="Add"
        key="id"
        label="text"
        :show-labels="false"
        track-by="text"
        :option-height="35"
        option-partial="category-option"
        @tag="addTag"
        @remove="removeSelection"
        @update="updateValue"
        @close="onClose"
        @select="onChange"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: {
    name: { type: String, default: null },
    placeholder: { type: String, default: "Select option" },
    selected: { type: [Array, Object, String, Number], default: () => [] },
    multiple: { type: Boolean, default: false },
    hideSelected: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: () => false },
    options: { type: [Array, Object], default: () => [] },
    taggable: { required: false, type: Boolean, default: false },
    allowEmpty: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      value: [],
      allOptions: [],
    };
  },
  computed: {
    getValue() {
      if (!this.multiple && !Array.isArray(this.value)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = [this.value];
        // return this.value;
      }
      /** Check if the user deselects the option and thus this.value has an element of null */
      if (!this.multiple && Array.isArray(this.value) && !this.value[0]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = [];
        // return this.value;
      }

      return this.value;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    value(newValue, oldValue) {
      // This will force the v-model of the parent to update
      this.$emit("input", this.value);
    },
  },

  mounted() {

    this.value = this.selected;
    this.allOptions = this.options;
  },
  methods: {
    // addSelection(option) {
    // if (this.multiple && !~this.value.indexOf(option)) {
    //   this.value.push(option);
    // } else if (!this.multiple) {
    //   this.value = option;
    // }
    // },
    removeSelection(option) {

      if (this.multiple) {
        // let index = this.value.indexOf(option);

        // if (~index) {
        // this.value.splice(index, 1);
        // console.log(this.value);
        // }
      }
      // this.clear();
    },
    updateValue(value) {
      this.value = value;
    },
    addTag(newTag) {

      const tag = {
        name: newTag,
        id: 0,
      };
      this.allOptions.push(tag);

      if (this.multiple) {
        this.value.push(tag);
      } else {
        this.value = tag;
      }


    },
    onClose() {
      this.$emit('touched')
    },
    onChange() {
      setTimeout(() => this.$emit('quote'), 1000)

    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
