const Types = {
    error: "Oops..",
    success: "Nice!",
    destroy: "Done!",
    info: "Hey..",
    new: "Add something new?",
    edit: "Editing something?"
};
const ButtonTypes = {
    primary: "btn primary-btn",
    secondary: "btn secondary-btn",
    destroy: "btn danger-btn",
    error: "btn danger-btn",
    info: "btn info-btn",
    new: "link-btn",
    edit: "btn info-btn",
    move: "btn info-btn",
    dropdown: "btn primary-btn",
    dropdown_destroy: "btn danger-btn",
    unlink: "btn danger-btn"

};
const BackgroundColors = {
    primary: "bg-teal-50",
    error: "bg-red-100",
    success: "bg-green-100",
    destroy: "bg-orange-100",
    info: "bg-blue-100",
    new: "bg-teal-50",
    edit: "bg-blue-100",
    move: "bg-blue-100",
    dropdown: "bg-teal-50",
    dropdown_destroy: "bg-orange-100",
    unlink: "bg-red-100"
};
const TextColors = {
    primary: "text-teal-500",
    error: "text-red-400",
    success: "text-green-400",
    destroy: "text-orange-400",
    info: "text-blue-400",
    new: "text-teal-500",
    edit: "text-blue-400",
    move: "text-blue-400",
    dropdown: "text-teal-500",
    dropdown_destroy: "text-orange-400",
    unlink: "text-red-400"
};
const ModalIcons = {
    error: "far fa-times",
    success: "far fa-check",
    destroy: "far fa-trash",
    info: "far fa-question",
    primary: "far fa-plus",
    new: "far fa-plus",
    edit: "far fa-pencil",
    move: "far fa-share-alt",
    dropdown: "far fa-question",
    dropdown_destroy: "far fa-question",
    unlink: "far fa-unlink"
};



export default { Types, BackgroundColors, TextColors, ModalIcons, ButtonTypes };
