<template>
    <div class="w-full">
        <ubiq-list
            :name="name"
            v-model="group"
            class="text-gray-700 border border-gray-300 rounded-md"
            :clear-on-select="true"
            :close-on-select="true"
            :options="groups"
            :allow-empty="true"
            :hide-selected="false"
            :selected="selected"
        ></ubiq-list>
        <span>
      <ubiq-form-modal
          :type="'new'"
          :header="'Add New Group'"
          :message="'Fill up the form below to preselect this new group.'"
          :submit-text="'Add this group'"
          :anchor="'Add New?'"
          :fa-icon="'far fa-layer-group'"
          :is-open="isOpen"
          :is-invalid="isInvalid"
          :is-saving="isSaving"
          @store="store"
          @toggle="toggle"
          @cancel="cancel"
      >
        <template v-slot:additional-content>
          <div class="text-center mt-3">
            <label for="name" class="block font-medium text-sm text-gray-700">
              Name <span class="text-red-500">*</span>
            </label>

            <input
                type="text"
                placeholder="Name"
                class="block my-2 text-center w-full rounded-md shadow-sm border-gray-300 focus:border-teal-400 focus:ring focus:ring-teal-300 focus:ring-opacity-50 placeholder-gray-300"
                v-model="form.name"
                :class="{
                  error: shouldAppendErrorClass($v.form.name),
                  valid: shouldAppendValidClass($v.form.name),
                }"
                @blur="$v.form.name.$touch()"
            />

            <div v-if="$v.form.name.$error && !$v.form.name.required">
              <span class="error-message">Name is required.</span>
            </div>

          </div>
        </template>
      </ubiq-form-modal>
    </span>
    </div>
</template>

<script>
import UbiqForm from "./UbiqForm";
import { required } from "vuelidate/lib/validators";
import ShouldAppendClassMixin from "./ShouldAppendClassMixin";

export default {
  mixins: [ShouldAppendClassMixin],
  props: {
    name: { type: String, default: null },
    selected: { type: Object, default: () => {} },
    groups: { type: [Array, Object], default: () => [] },
    postUri: { type: String, default: 'product-groups' }
  },
  data() {
    return {
      form: new UbiqForm({
        name: null,
        submit_button: 'submit'
      }),
      isOpen: false,
      isSaving: false,
      group: [{}],
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  computed: {
    isInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    reset() {
      this.$v.$reset();
      this.form.reset();
    },
    close() {
      this.isOpen = false;
    },
    cancel() {
      this.reset();
      this.close();
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    addNewItem({id, text}) {
        this.groups.push({ id: id, text: text });
        this.spliceIfHasPreselectedGroup();
        this.group.push({ id: id, text: text });

    },
      spliceIfHasPreselectedGroup()
      {
          if(this.group.length > 0) {
              this.group.splice(0);
          }
      },
    store() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        try {
          this.isSaving = true;
          this.form.post(`/${this.postUri}`).then((response) => {
            if (response.data.errors) {
              window.flash(response.data.message);
              this.reset();
            } else {
              //The response is successful.
              window.flash(response.data.message);
              this.addNewItem(response.data.group);
              this.reset();
              this.close();
            }
            this.isSaving = false;
          });
        } catch (error) {
          this.isSaving = false;
          this.form.errors = error.response.data.errors;
        }
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>
