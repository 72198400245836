<template>
  <div>
    <span class="shadow-sm rounded-md">
        <a v-if="isIconOnly" :class="textColor" class="m-1 hover:opacity-75 cursor-pointer" @click="isOpen = !isOpen" >
            <i
                class="h-4 w-4 -ml-1 inline-flex items-center"
                :class="buttonIcon"

            ></i>
        </a>
        <button v-else-if="isDropdownItem" type="button" class="group icon-link-text outline-none w-full focus:outline-none group-hover:text-gray-500" @click="isOpen = !isOpen">
        <i
            class="icon-link group-hover:text-gray-500"
            :class="buttonIcon"

        ></i>
        {{ anchor }}
      </button>
      <button v-else type="button" :class="buttonType" @click="isOpen = !isOpen">
        <i
          class="h-4 w-4 -ml-1 inline-flex items-center"
          :class="buttonIcon"

        ></i>
        {{ anchor }}
      </button>
    </span>
    <transition
      enter-active-class="transition-opacity ease-linear duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity ease-linear duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="fixed inset-0 overflow-y-auto z-40"
        v-show="isOpen"
      >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div
            class="absolute inset-0 bg-gray-500 opacity-75"
            @click="isOpen = !isOpen"
          ></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <transition
          enter-active-class="ease-in-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 z-50 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            v-show="isOpen"
          >
            <div>
              <div
                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full"
                :class="backgroundColor"
              >
                <i
                  :class="modalIcon"
                  class="mx-auto flex items-center justify-center h-6 w-6 fill-current"
                ></i>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3
                  class="text-lg leading-6 font-bold text-gray-900 truncate"
                  id="modal-headline"
                >
                  {{ header }}
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500 truncate">{{ message }}</p>
                </div>
                <slot name="additional-content" />
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <span class="flex w-full rounded-md shadow-sm sm:col-start-2">

                  <button type="submit" class="w-full btn" :name="name" :value="buttonVal" :class="buttonType" @click="isLoading = true" >
                    {{ (!isLoading) ? submitText : 'Processing...'}}
                  </button>


              </span>
              <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
              >
                <button
                  type="button"
                  class="w-full btn secondary-btn"
                  @click="isOpen = false"
                >
                  {{ cancelText }}
                </button>
              </span>
            </div>
          </div>
        </transition>
      </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ConstPlugin from "./ConstPlugin";

export default {
  props: {
    anchor: {
      type: String,
      default: "Delete",
    },
    isIconOnly: {
        type: Boolean,
        default: false
      },
      isDropdownItem: {
          type: Boolean,
          default: false
      },
    name: {
        type: String,
        default: "submit_button"
    },
    buttonVal: {
        type: String,
        default: "modal_submit"
    },
    faIcon: {
      type: String,
      default: "fad fa-trash",
    },
    type: {
      type: String,
      default: "success",
    },
    header: {
      type: String,
      default: "Your header goes here.",
    },
    message: {
      type: String,
      default: "Your message goes here.",
    },
    submitText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      modalIcon: null,
      buttonIcon: null,
      backgroundColor: null,
      textColor: null,

      buttonType: null,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
  mounted() {
    this.backgroundColor = ConstPlugin.BackgroundColors[this.type];
    this.textColor = ConstPlugin.TextColors[this.type];

    this.buttonIcon = this.faIcon;
    this.buttonType = ConstPlugin.ButtonTypes[this.type];
    this.modalIcon = `${ConstPlugin.ModalIcons[this.type]} ${this.textColor}`;
  },
};
</script>
