<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
    props: {
        datasource: { type: [Object], default: () => {} },
    },
    data: () => ({
        chartdata: {},
    options: {
        tooltips: {
            mode: 'nearest',
            callbacks: {
                label: function(tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label} : ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString()}`;
                }
            }
        },
        scales: {
					yAxes: [{
                        stacked: true,
						ticks: {
                            beginAtZero: true,

						    },
						    gridLines: {
							    display: true
						    }
					}],
					xAxes: [{
                        stacked: true,
						ticks: {
							beginAtZero: true,
                            callback : function(value,index,array) {
                                return (value < 1000000) ? value/1000 + 'K' : value/1000000 + 'M';
                            }
						},
						gridLines: {
							display: false
						}
					}]
				},
        responsive: true,
        maintainAspectRatio: false
    }
  }),

    methods: {
        initialize({labels, datasets})
        {
            this.chartdata.labels = labels;
            this.chartdata.datasets = datasets;
        }
    },
    mounted () {
        this.initialize(this.datasource);
        this.renderChart(this.chartdata, this.options)
    }
}
</script>
