<template>
  <div>
    <span class="">
      <button type="button" class="btn info-btn w-full" @click="isOpen = !isOpen">
        <i
            class="h-4 w-4 -ml-1 inline-flex items-center far fa-key"
        ></i>
        Change Password
      </button>
    </span>
    <form @submit.prevent="submit">
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
          v-show="isOpen"
        >
          <div class="fixed inset-0 transition-opacity">
            <div
              class="absolute inset-0 bg-gray-500 opacity-75"
              @click="isOpen = !isOpen"
            ></div>
          </div>

          <transition
            enter-active-class="ease-in-out duration-300"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
              v-show="isOpen"
            >
              <div>
                <div
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-50"
                >
                  <i
                    class="far fa-key"

                  ></i>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                  >
                    Change Password
                  </h3>
                  <div class="mt-1 mb-2">
                    <p class="text-sm text-gray-500">
                      Fill up the form below to set a new password.
                    </p>
                  </div>
                  <div class="mb-2">
                    <label for="password" class="ubiq-input-label"
                      >Password</label
                    >
                    <input
                      id="password"
                      v-model="form.password"
                      type="password"
                      name="password"
                      class="w-full text-center rounded-md shadow-sm border-gray-300 focus:border-teal-400 focus:ring focus:ring-teal-300 focus:ring-opacity-50 placeholder-gray-300"
                      :class="{
                        error: shouldAppendErrorClass($v.form.password),
                        valid: shouldAppendValidClass($v.form.password),
                      }"
                      @blur="$v.form.password.$touch()"
                    />
                    <span v-if="$v.form.password.$error">
                      <span
                        v-if="!$v.form.password.required"
                        class="error-message"
                        >Password is required.</span
                      >
                      <span
                        v-if="!$v.form.password.minLength"
                        class="error-message"
                        >Password must have at least
                        {{
                          $v.form.password.$params.minLength.min
                        }}
                        letters.</span
                      >
                    </span>
                  </div>
                  <div>
                    <label for="password_confirmation" class="ubiq-input-label"
                      >Confirm Password</label
                    >
                    <input
                      id="password_confirmation"
                      v-model="form.password_confirmation"
                      type="password"
                      name="password_confirmation"
                      class="w-full text-center rounded-md shadow-sm border-gray-300 focus:border-teal-400 focus:ring focus:ring-teal-300 focus:ring-opacity-50 placeholder-gray-300"
                      :class="{
                        error: shouldAppendErrorClass(
                          $v.form.password_confirmation
                        ),
                        valid: shouldAppendValidClass(
                          $v.form.password_confirmation
                        ),
                      }"
                      @blur="$v.form.password_confirmation.$touch()"
                    />
                    <span v-if="$v.form.password_confirmation.$error">
                      <span class="error-message"
                        >Confirm password does not match.</span
                      >
                    </span>
                  </div>
                </div>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                >
                  <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
                    <button
                      type="submit"
                      class="w-full btn info-btn"
                      :disabled="$v.form.$invalid"
                    >
                      Submit
                    </button>
                  </span>
                  <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
                  >
                    <button
                      type="button"
                      class="w-full btn secondary-btn"
                      @click="cancel"
                    >
                      Cancel
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </form>
  </div>
</template>

<script>
import UbiqForm from "./UbiqForm";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import ShouldAppendClassMixin from "./ShouldAppendClassMixin";
export default {
  mixins: [ShouldAppendClassMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    url: {
      type: String,
    },
  },
  data() {
    return {
      form: new UbiqForm({
        password: "",
        password_confirmation: "",
      }),
      account: this.user,
      errors: {},
      isOpen: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    reset() {
      this.$v.$reset();
      this.form.reset();
    },
    close() {
      this.isOpen = false;
    },
    cancel() {
      this.reset();
      this.close();
    },
    submit() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        // if(!this.form.tasks[0].body) {
        // 	delete this.form.originalData.tasks;
        // }
        try {

          this.form.patch(this.url).then((response) => {
            window.flash(response.data.message);
            this.reset();
            this.close();
          });
        } catch (error) {
          this.errors = error.response.data.errors;
        }
      }

      // try {
      //     location = ( axios.post('/projects', this.form)).data.message;
      //     // this.$modal.hide('new-project');

      // } catch (error) {
      //     this.errors = error.response.data.errors;
      // }
    },
  },
};
</script>

<style>
</style>
